import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
// import { AnchorLink } from "gatsby-plugin-anchor-links";
// import AniLink from "gatsby-plugin-transition-link/AniLink"
// import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
// import ScrollWrapper from "../components/Scroll/ScrollWrapper"
// import ScrollNavigation from "../components/Scroll/ScrollNavigation"
// import ScrollContent from "../components/Scroll/ScrollContent"
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   // const processBgImages = [
   // 	data.processBgDesktop.childImageSharp.fixed,
   // 	{
   // 	...data.processBgDesktop.childImageSharp.fixed,
   // 	media: `(max-width: 767px)`,
   // 	},
   // ]

   // const url = typeof window !== 'undefined' ? window.location.pathname : '';

   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Special Effects Props & Theming Company | Painted Rhino"
            description="Painted Rhino provides special effects props and solutions for filmmakers, film production companies, theater, and more. Get a free quote today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-6 md:pt-14 pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12">
                  <h1>Special Effects Props & Theming</h1>
               </header>

               <div className="mb-12 md:mb-28">
                  <Img fadeIn={false} loading="eager" fluid={data.heroDesktop.childImageSharp.fluid} alt="Fiberglass & Composites" />
               </div>

               <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-20">
                  <div className="md:col-start-1 md:col-span-6">
                     <h2>Our Special Effects Props & Theming Company</h2>
                  </div>
                  <div className="md:col-end-13 md:col-span-6">
                     <p className="mb-0">
                        Painted Rhino provides props and special effects solutions for filmmakers, film production companies, theater, and more. With
                        our various in-house capabilities and creative build team, we can fabricate your props, backgrounds, and displays of all
                        sizes. From creature suits to custom scenery, we help you tell your story effectively.{" "}
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-2 md:gap-4">
                  <div className="grid grid-rows-2 grid-cols-10 gap-2 md:gap-4">
                     <div className="col-start-1 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-start-1 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-rows-2 grid-cols-10 grid-flow-col gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="row-span-1 col-span-6">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <TestimonialBackgroundImage />

         <WhyUs
            className="pt-22 md:pt-32 pb-20 md:pb-28"
            contentAlignment="md:text-center md:mx-auto"
            headerHeading="The Painted Rhino Difference."
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Special Effects Props_Theming_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Special Effects Props_Theming_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/special-effects-props-theming.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2400) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      processBgDesktop: file(relativePath: { eq: "services/process-bg-desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Top-left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/saddle ranch.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Top-middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/cylon costume.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Bottom-left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/IMG_0769.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Bottom-middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/ty fighter.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/image030.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Top-right.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/star wars.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Thumbnails/Bottom-right.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "services/Special-Effects-Props-Theming/Gallery/Lightbox/IMG_3078.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
